import { mapActions, mapGetters, mapState } from 'vuex'
import Metrics from '@/plugins/metrics'

import {
  frequencyItems,
  happenedToOptions,
} from '@/views/Reports/ReportForm/constants'
import CompanyRelationshipField from '@/components/ReportFields/CompanyRelationshipField'

export default {
  name: 'StepOne',

  props: {
    report: {
      type: Object,
      required: true,
    },
    showRelationOptions: {
      type: Boolean,
      default: false,
    },
    experimentName: {
      type: String,
      required: true,
    },
    displayOriginalFlow: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    CompanyRelationshipField,
  },

  data() {
    return {
      happenedToOptions,
      frequencyItems,
      misconducts: [],
      happenedAtOther: false,
      workShiftOther: false,
      maxLength: 25,
      branchSearched: [],
      branchSelected: null,
    }
  },

  async mounted() {
    await this.getCompanyBranches()

    const { source, channel, anonymous } = this.report
    const payloadMetrics = {
      source,
      channel,
      anonymous,
    }

    if (this.displayOriginalFlow) {
      this.misconducts = await this.getMisconducts()
    }

    Metrics.track('Report Step One Started', payloadMetrics)

    if (this.report?.anonymous) {
      Metrics.track('Report Started', {
        ...payloadMetrics,
        mode: 'ANONYMOUS',
      })
      return
    }

    if (this.report?.match) {
      Metrics.track('Report Started', {
        ...payloadMetrics,
        mode: 'CONNECT',
      })
      return
    }

    Metrics.track('Report Started', {
      ...payloadMetrics,
      mode: 'IDENTIFIED',
    })
  },

  computed: {
    ...mapState(['company', 'branches', 'locale']),
    ...mapGetters(['collaboratorRelations', 'isFeatureEnabled']),

    isRecurrent() {
      return this.report.recurrent
    },

    currentDate() {
      return new Date().toJSON().split('T')[0]
    },

    hasCustomMisconduct() {
      if (!this.displayOriginalFlow) return false
      return this.report.misconducts.some((item) => item.localeKey === 'OTHER')
    },

    validLocale() {
      return this.locale === 'pt-BR' ? 'pt' : this.locale
    },

    branchWithLocaleCurrent() {
      return this.branches.map((branch) => branch[this.validLocale])
    },

    shouldFilterBranch() {
      return this.branchWithLocaleCurrent.length > 250
    },

    isCompanyBranchEnabled() {
      return this.isFeatureEnabled('companyBranch') && this.branches.length > 0
    },

    branchOptions() {
      return this.branches.map((option) => ({
        key: option.key,
        name: option[this.validLocale],
        toLowerCase: () => option[this.validLocale].toLowerCase(),
        toString: () => option[this.validLocale],
      }))
    },

    sortMisconducts() {
      const sortedMisconducts = this.misconducts.map((el) => {
        const localeKey = this.$t(el.localeKey)
        return { ...el, localeKey }
      })

      sortedMisconducts.sort((a, b) => a.localeKey.localeCompare(b.localeKey))

      return sortedMisconducts
    },
  },

  watch: {
    'report.happenedAt': {
      deep: true,
      immediate: true,
      async handler() {
        if (this.report.happenedAt) {
          this.happenedAtOther = null
        }
      },
    },
  },

  methods: {
    ...mapActions(['getMisconducts', 'getCompanyBranches']),

    resetInput() {
      this.report.happenedAt = null
      this.$refs.inputHappenedAt.value = ''
    },

    getBranches(searchTerm) {
      const removeAccentedWord = (str = '') => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      }
      if (!searchTerm || (searchTerm.length <= 3 && this.shouldFilterBranch)) {
        this.branchSearched = []
        return
      }

      const term = removeAccentedWord(searchTerm.toLowerCase())

      this.branchSearched = this.branches.filter((branch) =>
        removeAccentedWord(branch[this.validLocale].toLowerCase()).includes(
          term,
        ),
      )
    },

    onSelectBranch(branch) {
      this.report.companyBranch = branch.key
    },
  },
}
